import { isMobile } from "react-device-detect";
import { enums } from "../utils/constants";
import { trackShare } from "./analytics";
import teamMap from "./teamMap";
import { calcRarity } from "./calcRarity";
import { rarityScheme } from "./rarityScheme";
import useGameStore from "../store/GameStore";
import usePersistentStore from "../store/PersistentStore";

export function handleTweet() {
  const levelNumber = useGameStore.getState().levelNumber;
  const gameName = useGameStore.getState().shareGameName;
  const gameUrl = useGameStore.getState().shareGameUrl;
  const grid = useGameStore.getState().grid;
  const globalStats = useGameStore.getState().globalStats;
  const score = usePersistentStore.getState().score;
  const correctGuesses = usePersistentStore.getState().correctGuesses;
  const cardMode = usePersistentStore.getState().cardMode;

  const copy = tweetCopy(
    grid,
    levelNumber,
    correctGuesses,
    globalStats,
    score,
    gameName,
    gameUrl,
    cardMode
  );

  return copy;
}

export async function handleShare() {
  const levelNumber = useGameStore.getState().levelNumber;
  const gameName = useGameStore.getState().shareGameName;
  const gameUrl = useGameStore.getState().shareGameUrl;
  const grid = useGameStore.getState().grid;
  const globalStats = useGameStore.getState().globalStats;
  const score = usePersistentStore.getState().score;
  const correctGuesses = usePersistentStore.getState().correctGuesses;
  const cardMode = usePersistentStore.getState().cardMode;

  const copy = shareCopy(
    grid,
    levelNumber,
    correctGuesses,
    globalStats,
    score,
    gameName,
    gameUrl,
    cardMode
  );

  share({ copy, levelNumber });
}

export function shareCopy(
  grid,
  levelNumber,
  correctGuesses,
  globalStats,
  score,
  gameName,
  gameUrl,
  cardMode
) {
  const emojiText = emojiGrid(grid, correctGuesses, globalStats, cardMode);
  const scoreText = score == 9 ? "PERFECT!\n" : "";
  const rarity = calcRarity(correctGuesses, globalStats);

  const copy = `${gameName} ${levelNumber} - ${score}/9

${scoreText}${emojiText}Rarity: ${rarity}

${gameUrl}`;

  console.log(copy);

  return copy;
}

export function tweetCopy(
  grid,
  levelNumber,
  correctGuesses,
  globalStats,
  score,
  gameName,
  gameUrl,
  cardMode
) {
  const emojiText = emojiGrid(grid, correctGuesses, globalStats, cardMode);
  const scoreText = score == 9 ? "PERFECT!\n" : "";
  const rarity = calcRarity(correctGuesses, globalStats);

  const copy = `BikeGrid #${levelNumber} - ${score}/9

${scoreText}${emojiText}Rarity: ${rarity}

Play #BikeGrid by @EscapeCycling https://bikegrid.escapecollective.com`;

  return copy;
}

export async function share({ copy, levelNumber }) {
  if ("share" in navigator && isMobile) {
    const shareData = {
      text: copy,
    };

    try {
      await navigator.share(shareData);
      trackShare({ method: "Mobile", itemId: levelNumber });
    } catch (err) {
      console.log(err);
    }
  } else {
    try {
      updateClipboard(copy);
      trackShare({ method: "Copy", itemId: levelNumber });
    } catch (err) {
      console.log(err);
    }
  }
}

function emojiGrid(grid, correctGuesses, globalStats, cardMode) {
  let text = "";

  // grid.hItems.forEach((hItem) => {
  //   text += hItem.emoji;
  // });
  // text += "\n";

  correctGuesses.forEach((row, rowIndex) => {
    const vItem = grid.vItems[rowIndex];
    // text += vItem.emoji;

    row.forEach((col, colIndex) => {
      if (col == null) {
        text += "⬜";
      } else if (!cardMode) {
        text += "🟩";
      } else {
        const correctAnswersPlayerMap =
          globalStats?.correctAnswersPlayerMap[rowIndex][colIndex];
        const correctPlayerId = col;
        const pct = correctAnswersPlayerMap
          ? (correctAnswersPlayerMap[correctPlayerId] / globalStats.completed) *
            100
          : 0.1;

        const scheme = rarityScheme(pct);

        text += scheme.emojiSquare;
      }
    });
    text += "\n";
  });

  return text;
}

function keyToEmoji(key) {
  const type = key.length > 2 ? "award" : "team";

  return type == "award" ? awardsMap[key].emoji : teamMap[key].emoji;
}

function updateClipboard(newClip) {
  return navigator.clipboard.writeText(newClip);
}
