import Image from "next/image";
import { HowToPlayLink } from "./buttons/HowToPlayLink";
import Link from "next/link";

export function Footer() {
  return (
    <>
      {/* <AdFooter /> */}
      {/* <GameFooter /> */}
      <EscapeFooter />
    </>
  );
}

function AdFooter() {
  return (
    <div className="static bottom-2 left-0 right-0 mx-auto mb-4 mt-24 h-[50px] max-h-[50px] max-w-screen-lg text-center sm:h-[90px] sm:max-h-[90px] md:mb-12 md:mt-20">
      <ins
        className="adsbygoogle h-[50px] max-h-[50px] sm:h-[90px] sm:max-h-[90px]"
        style={{
          display: "block",
          // width: "320px",
          // backgroundColor: "#CCCCCC",
        }}
        data-ad-client="ca-pub-4746633404428334"
        data-ad-slot="4370566560"
      ></ins>
    </div>
  );
}

function GameFooter() {
  return (
    <footer className="border-b border-t border-b-soft-grey border-t-mid-grey/40 bg-white px-4 py-6 dark:bg-gray-800 md:py-8 ">
      <div className="mx-auto max-w-screen-xl text-center">
        {/* <a href="https://gridleygame.com/" className="mb-2 block">
          <Image
            src={gridleyLogo}
            alt="BikeGrid Game Logo"
            className="grayscale"
            layout="intrinsic"
            width={115}
            height={24}
          />
        </a> */}

        <ul className="mb-6 flex flex-wrap items-center justify-center space-x-8 text-sm text-black dark:text-white md:mb-8 md:space-x-10 md:text-base">
          <li>
            <HowToPlayLink />
          </li>
          <li>
            <Link href="/privacy" passHref>
              <a className="hover:underline">Privacy Policy</a>
            </Link>
          </li>
          <li>
            <a
              className="hover:underline"
              href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#116;&#101;&#97;&#109;&#64;&#103;&#114;&#105;&#100;&#108;&#101;&#121;&#103;&#97;&#109;&#101;&#46;&#99;&#111;&#109;"
            >
              Contact
            </a>
          </li>
        </ul>
        {/* <div className="mb-6 mt-4 flex justify-center space-x-5 md:mb-8">
          <a
            href="https://twitter.com/BikeGridGame"
            target="_blank"
            className="text-gray-700 hover:text-black dark:hover:text-white"
          >
            <svg
              className="h-7 w-7 md:h-8 md:w-8"
              aria-hidden="true"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
            </svg>

            <span className="sr-only">Twitter page</span>
          </a>
          <a
            href="https://www.tiktok.com/@gridleygame"
            target="_blank"
            className="text-gray-700 hover:text-black dark:hover:text-white"
          >
            <svg
              className="h-7 w-7 md:h-8 md:w-8"
              viewBox="0 0 512 512"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M412.19 118.66a109.27 109.27 0 01-9.45-5.5 132.87 132.87 0 01-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69 0 .52-.05 1-.08 1.56 0 .23 0 .47-.05.71v.18a70 70 0 01-35.22 55.56 68.8 68.8 0 01-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0121.41 3.39l.1-83.94a153.14 153.14 0 00-118 34.52 161.79 161.79 0 00-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 00115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34 7.66-.31 33.32 0 62.46-13.81 32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0027.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61z" />
            </svg>
            <span className="sr-only">TikTok account</span>
          </a>
        </div> */}
        <h1 className="mb-2 text-sm text-black dark:text-gray-400">
          BikeGrid - The daily grid game for pro cycling fans
        </h1>
      </div>
    </footer>
  );
}

function EscapeFooter(params) {
  return (
    // <footer className="border-t border-t-mid-grey/40 bg-black md:bg-off-white">
    <footer className="footer mx-auto max-w-screen-lg ">
      <div className="footer__left">
        <div className="footer__left-top">
          <div className="footer__social-links">
            <a
              href="https://www.facebook.com/escapecollectivecc"
              aria-label="Facebook"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="20"
                fill="none"
                role="img"
              >
                <title>Facebook</title>
                <path
                  fill="#181818"
                  d="M6.774 20v-9.121h3.064l.459-3.555H6.778V5.052c0-1.03.286-1.732 1.761-1.732h1.885V.141A25.069 25.069 0 0 0 7.679 0C4.966 0 3.107 1.656 3.107 4.698v2.626H.037v3.555h3.07V20h3.667Z"
                />
              </svg>
            </a>
            <a href="https://www.x.com/escapecycling/" aria-label="X">
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>X</title>
                <g clipPath="url(#a)">
                  <path
                    d="M14.347 10.157 23.031 0h-2.058l-7.54 8.82L7.411 0H.464l9.107 13.336L.464 23.988h2.058l7.963-9.314 6.36 9.314h6.947l-9.445-13.83Zm-2.819 3.297-.922-1.328L3.264 1.559h3.16l5.926 8.528.923 1.328L20.974 22.5h-3.16l-6.286-9.046Z"
                    fill="#181818"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path
                      fill="#fff"
                      transform="translate(.464)"
                      d="M0 0h23.327v24H0z"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/escapecollective/"
              aria-label="Instagram"
            >
              <svg
                width="25"
                height="25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Instagram</title>
                <path
                  d="M7.466.502C3.63.502.5 3.632.5 7.47v10.067c0 3.837 3.13 6.966 6.966 6.966h10.068c3.836 0 6.966-3.13 6.966-6.966V7.47c0-3.837-3.13-6.967-6.966-6.967H7.466Zm0 1.847h10.068a5.105 5.105 0 0 1 5.12 5.12v10.067a5.105 5.105 0 0 1-5.12 5.12H7.466a5.105 5.105 0 0 1-5.12-5.12V7.47a5.105 5.105 0 0 1 5.12-5.12Zm11.957 1.846a1.385 1.385 0 1 0 0 2.77 1.385 1.385 0 0 0 0-2.77ZM12.5 6.04c-3.559 0-6.462 2.903-6.462 6.461 0 3.56 2.903 6.462 6.462 6.462 3.559 0 6.462-2.903 6.462-6.462 0-3.558-2.903-6.461-6.462-6.461Zm0 1.846a4.601 4.601 0 0 1 4.615 4.615 4.601 4.601 0 0 1-4.615 4.616 4.601 4.601 0 0 1-4.615-4.616A4.601 4.601 0 0 1 12.5 7.887Z"
                  fill="#181818"
                />
              </svg>
            </a>
            <a
              href="https://www.tiktok.com/@escapecollective"
              aria-label="Tiktok"
            >
              <svg
                width="21"
                height="25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Tiktok</title>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.284.502C15.688 3.908 17.628 5.94 21 6.155v3.83c-1.954.187-3.666-.44-5.657-1.62v7.164c0 9.101-10.124 11.945-14.194 5.422C-1.466 16.753.135 9.388 8.525 9.092v4.04c-.639.1-1.322.259-1.947.468-1.866.619-2.924 1.778-2.63 3.823.566 3.917 7.898 5.076 7.288-2.578V.51h4.048V.502Z"
                  fill="#181818"
                />
              </svg>
            </a>
          </div>
          <nav
            role="navigation"
            aria-label="Footer menu"
            className="hide-in-mobile"
          >
            <ul id="menu-footer-menu-right" className="footer__menu">
              <li
                id="menu-item-486"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-486"
              >
                <a href="https://escapecollective.com/about-us/">About</a>
              </li>
              <li
                id="menu-item-490"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-490"
              >
                <a href="https://escapecollective.com/founders-wall/">
                  Founders
                </a>
              </li>
              <li
                id="menu-item-491"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-491"
              >
                <a href="https://escapecollective.com/team/">Team</a>
              </li>
              <li
                id="menu-item-488"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-488"
              >
                <a href="https://escapecollective.com/careers/">Careers</a>
              </li>
              <li
                id="menu-item-489"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-489"
              >
                <a href="https://escapecollective.com/faqs/">FAQs</a>
              </li>
              <li
                id="menu-item-492"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-492"
              >
                <a href="https://escapecollective.com/terms-and-conditions/">
                  T&Cs
                </a>
              </li>
              <li
                id="menu-item-485"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-485"
              >
                <a
                  rel="privacy-policy"
                  href="https://escapecollective.com/privacy-policy/"
                >
                  Policies
                </a>
              </li>
              <li
                id="menu-item-5262"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5262"
              >
                <a href="https://escapecollective.com/support/">Support</a>
              </li>
              <li
                id="menu-item-28657"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-28657"
              >
                <a href="https://escapecollective.com/our-story/">Our story</a>
              </li>
              <li
                id="menu-item-86714"
                className="menu-item menu-item-type-post_type menu-item-object-post menu-item-86714"
              >
                <a href="https://escapecollective.com/the-2023-24-escape-collective-transparency-report/">
                  Transparency
                </a>
              </li>
            </ul>{" "}
          </nav>
        </div>
        <div className="footer__left-bottom notranslate">
          <img
            alt="Escape Collective logo"
            width="159"
            height="402"
            src="https://escapecollective.com/wp-content/uploads/2024/07/ESC_Symbol_Black.svg"
            loading="lazy"
            className="logo hide-in-desktop"
          />
          <p className="caption hide-in-mobile">
            ©2024 Escape Collective
            <br />
            <br />
            <a
              href="https://poweruptech.com.au/"
              target="_blank"
              className="hover:underline"
            >
              BikeGrid Built by PowerUp Tech
            </a>
          </p>
        </div>
      </div>
      <div className="footer__right">
        <div className="footer__right-top hide-in-desktop">
          <nav role="navigation" aria-label="Footer menu">
            <ul id="menu-footer-menu-right-1" className="footer__menu">
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-486">
                <a href="https://escapecollective.com/about-us/">About</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-490">
                <a href="https://escapecollective.com/founders-wall/">
                  Founders
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-491">
                <a href="https://escapecollective.com/team/">Team</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-488">
                <a href="https://escapecollective.com/careers/">Careers</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-489">
                <a href="https://escapecollective.com/faqs/">FAQs</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-492">
                <a href="https://escapecollective.com/terms-and-conditions/">
                  T&Cs
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-485">
                <a
                  rel="privacy-policy"
                  href="https://escapecollective.com/privacy-policy/"
                >
                  Policies
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5262">
                <a href="https://escapecollective.com/support/">Support</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-28657">
                <a href="https://escapecollective.com/our-story/">Our story</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-86714">
                <a href="https://escapecollective.com/the-2023-24-escape-collective-transparency-report/">
                  Transparency
                </a>
              </li>
            </ul>{" "}
          </nav>
        </div>
        <a
          href="https://escapecollective.com"
          aria-label="Escape Collective logo"
        >
          <img
            alt="Riding is Life"
            width="141"
            height="48"
            src="https://escapecollective.com/wp-content/themes/escapecollective/assets/img/ESC_RIL_Black_Large.svg"
            loading="lazy"
            className="footer_ril"
          />
          <img
            alt="Escape Collective logo"
            width="194"
            height="197"
            src="https://escapecollective.com/wp-content/uploads/2024/07/ESC_Symbol_Black.svg"
            loading="lazy"
            className="logo hide-in-mobile"
          />
        </a>
        <p className="caption hide-in-desktop">
          ©2024 Escape Collective
          <br />
          <a
            href="https://poweruptech.com.au/"
            target="_blank"
            className="hover:underline"
          >
            BikeGrid Built by PowerUp Tech
          </a>
        </p>
      </div>
    </footer>
    // </footer>
  );
}
