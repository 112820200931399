import { useState } from "react";
import { UndoDialog } from "../modals/UndoDialog";

export function UndoButton({ player, col, row }) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal(isConfirmed) {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        onClick={openModal}
        type="button"
        className="-my-1.5 inline-flex items-center rounded-lg border border-mid-grey/40 bg-off-white px-3 pb-1.5 pt-2 text-center text-xs font-medium text-black hover:border-gray-300  hover:bg-soft-grey focus:outline-none  focus:ring-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 dark:border-gray-700 dark:bg-gray-600  dark:text-black  dark:hover:bg-gray-700 dark:focus:ring-gray-800"
      >
        <svg
          className="mb-0.5 mr-2 h-4 w-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 940 1000"
        >
          <path d="M532 90c113.333 0 209.667 40 289 120s119 176.667 119 290-39.667 210-119 290-175.667 120-289 120c-92 0-176-29.333-252-88l70-76c54.667 40 115.333 60 182 60 84 0 156-30 216-90s90-132 90-216c0-85.333-30-158-90-218s-132-90-216-90c-82.667 0-153.667 28.667-213 86s-90.333 127.333-93 210h142L184 694 0 488h124c2.667-110.667 43.667-204.667 123-282S421.333 90 532 90m-36 190h70v204l130 130-50 50-150-150V280" />
        </svg>
        Undo
      </button>

      <UndoDialog
        player={player}
        col={col}
        row={row}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </>
  );
}
