export function GridHeadshot({ player, silhouette }) {
  const silhouetteStyle = silhouette ? "opacity-80 contrast-0" : "";

  return player.photo_url ? (
    <img src={player.photo_url} className={`w-full ${silhouetteStyle}`} />
  ) : (
    <img src="/img/silhouette.png" className={`w-full ${silhouetteStyle}`} />
  );
}

export function ChampionHeadshot({ player, ...props }) {
  return (
    <img
      src={`https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/AFL/${player.last}014/${player.c}.png`}
      {...props}
    />
  );
}

export function LocalHeadshot({ player, ...props }) {
  return (
    <img
      src={`/img/headshots/${player.id}-${player.name
        .replaceAll(" ", "-")
        .toLowerCase()}.png`}
      {...props}
    />
  );
}
