import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import useGameStore from "../../lib/store/GameStore";
import { NetworkError } from "../NetworkError";
import { Footer } from "../Footer";
import Image from "next/image";
import gridleyLogo from "../../../public/img/escape.svg";
import { PromoBanner } from "../PromoBanner";
import { shallow } from "zustand/shallow";
import ResultDialog from "../modals/ResultDialog";
import PickHistoryDialog from "../modals/PickHistoryDialog";
import HowToPlayDialog from "../modals/HowToPlayDialog";
import LoginDialog from "../modals/LoginDialog";
import { HeaderIcons } from "../HeaderIcons";
import "react-toastify/dist/ReactToastify.css";
import PromoDialog from "../modals/PromoDialog";
import Link from "next/link";
import GameHistoryDialog from "../modals/GameHistoryDialog";

function BasePage(props) {
  const [initError, setupLevel] = useGameStore(
    (state) => [state.initError, state.setupLevel],
    shallow
  );

  const [adsLoaded, setAdsLoaded] = useState(false);

  const init = async () => {
    setupLevel();
  };

  // Set up game
  useEffect(async () => {
    await init();

    // if (!adsLoaded) {
    //   (window.adsbygoogle = window.adsbygoogle || []).push({});
    //   setAdsLoaded(true);
    // }
  }, []);

  return (
    <>
      <div className="relative bg-off-white">
        <PromoBanner />
        <header className="border-b border-orange-400 bg-orange-400 text-center">
          <div className="mx-auto flex max-w-screen-lg items-center justify-between px-4 py-3 md:px-6 md:py-3">
            <h2 className="">
              <a
                className="image-block-hack font-headline text-3xl sm:text-4xl"
                href="/"
              >
                <img
                  src="/img/ESC_BikeGrid_Logo_Black.svg"
                  alt="Escape Collective logo"
                  className="mt-0.5 h-5 sm:h-8"
                />
                <span className="sr-only">BikeGrid</span>
              </a>
            </h2>
            <HeaderIcons />
          </div>
        </header>

        {props.children}

        {/* <PromoDialog /> */}
      </div>
      <div className="relative bg-orange-400">
        <Footer />
      </div>
      <ResultDialog />
      <PickHistoryDialog />
      <GameHistoryDialog />
      <HowToPlayDialog />
      <LoginDialog />
      <MyToastContainer />
    </>
  );
}

export default BasePage;

function MyToastContainer() {
  const contextClass = {
    success: "bg-blue-600",
    error: "bg-red-600",
    info: "bg-gray-600",
    warning: "bg-orange-400",
    default: "bg-indigo-600",
    dark: "bg-white-600 font-gray-300",
  };

  return (
    <ToastContainer
      bodyClassName="font-sans text-sm text-black"
      autoClose={3000}
    />
  );
}
