import {
  addDays,
  dateKeyToDate,
  getDaysBetweenDatesExcludingWeekends,
} from "./dateFunctions";
import { getMidnight, dateToKey } from "./dateFunctions";

const startDate = new Date("Mar 5 2024");
export const startDateMidnight = getMidnight(startDate);
const todayMidnight = getMidnight(new Date());
export const removeWeekendPlayDate = new Date("2024-04-11");

export const gridLevelNumber = getDaysBetweenDatesExcludingWeekends(
  todayMidnight,
  startDateMidnight
);

export const gridLevelDateKey =
  extractDateFromURLPath() ?? getURLParameter("preview") ?? dateToKey();
const gridLevelDate = dateKeyToDate(gridLevelDateKey);

export const isWeekend =
  getURLParameter("weekend") ||
  gridLevelDate.getDay() === 0 ||
  gridLevelDate.getDay() === 6;

export function getDateKeyForLevel(level) {
  const levelDate = addDays(startDateMidnight, level - 1);

  return dateToKey(levelDate);
}

function getURLParameter(name) {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  } else {
    return null;
  }
}

export function extractDateFromURLPath() {
  if (typeof window !== "undefined") {
    const currentPath = window.location.pathname;
    const regex = /\/(\d{4}-\d{2}-\d{2})/;
    const match = currentPath.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  } else {
    return null;
  }
}
