import { useEffect, useState } from "react";
import { IconCards } from "./icons/IconCards";
import { IconCardsOutline } from "./icons/IconCardsOutline";
import usePersistentStore from "../lib/store/PersistentStore";

export function PromoBanner() {
  const [cardMode, toggleCardMode] = usePersistentStore((state) => [
    state.cardMode,
    state.toggleCardMode,
  ]);

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (cardMode) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [cardMode]);

  function toggleMode() {
    toggleCardMode();
  }

  return (
    <div className="w-screen bg-black p-2 px-0 text-center text-xs  text-white sm:text-sm md:p-3">
      {/* <span className="hidden md:inline-block">Watch Now</span> */}
      {/* Play Fantasy alongside the Vuelta España Feminina and Giro d'Italia! */}
      Now includes riders from 2000 to 2024!
      {/* <a
        className="text-white-400 whitespace-nowrap font-semibold underline"
        href="https://escapecollective.com/vuelta-a-fantasy/"
        target="_blank"
      >
        Vuelta a España
      </a>{" "} */}
    </div>
    // <div className="w-screen bg-yellow-100 p-2 px-6 text-center text-sm text-black sm:text-sm md:p-3">
    //   {/* <span className="hidden md:inline-block">Watch Now</span> */}✨
    //   Introducing{" "}
    //   <a
    //     className="font-bold text-blue-600 underline dark:text-blue-400"
    //     href="https://pokegrid.net"
    //     target="_blank"
    //   >
    //     PokeGrid
    //   </a>{" "}
    //   – the BikeGrid x Pokémon crossover! 🚀
    //   {/*
    //   <a
    //     className="whitespace-nowrap text-blue-600 underline dark:text-blue-400 "
    //     href="https://twitter.com/BikeGridGame/status/1683507357962375168"
    //     target="_blank"
    //   >
    //     Most Popular
    //   </a>{" "}
    //   <span className="md:hidden">Note: </span>Brisbane{" "}
    //   <strong>now includes</strong> Brisbane Bears players */}
    // </div>
  );
}
