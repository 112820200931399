export const PRECISION = 3;
export const MAX_GUESSES = 9;

export const arrowDir = {
  NONE: "",
  UP: "↑",
  DOWN: "↓",
  LEFT: "←",
  RIGHT: "→",
};

export const guessStatus = {
  INCORRECT: 0,
  CLOSE: 1,
  CORRECT: 2,
};

export const scoreWords = {
  0: "Bad Luck",
  1: "Room to improve",
  2: "Respectable",
  3: "Decent",
  4: "Nice Work",
  5: "Awesome",
  6: "Impressive",
  7: "Outstanding",
  8: "Magnificent",
  9: "PERFECT",
};

export const enums = {
  levelResult: {
    TBD: 1,
    WON: 2,
    LOST: 3,
  },
  game: {
    GRIDLEY: "gridley",
  },
};
