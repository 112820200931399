import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";
import { BaseDialog } from "./BaseDialog";

function HowToPlayDialog({ modalTitle = "How to Play BikeGrid" }) {
  const [isHelpOpen, setIsHelpOpen] = useGameStore((state) => [
    state.isHelpOpen,
    state.setIsHelpOpen,
  ]);

  const [instructionsDismissed, saveInstructionsDismissed] = usePersistentStore(
    (state) => [state.instructionsDismissed, state.saveInstructionsDismissed]
  );

  const isOpen = isHelpOpen || !instructionsDismissed;

  function closeModal() {
    saveInstructionsDismissed(true);
    setIsHelpOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle={modalTitle}
        modalBody={<HowToPlayBody />}
        size="lg"
      />
    </>
  );
}

function HowToPlayBody() {
  return (
    <div className="my-2 px-2 pt-2 text-md sm:px-4">
      <ul className="list-none space-y-5">
        <HelpListItem
          afterText={<>You have nine guesses to fill out the grid.</>}
        />
        <HelpListItem
          afterText={
            <>
              Select a past or present rider (2001 onwards) for each cell that
              meets the criteria for that cell's row and column.
            </>
          }
        />
        {/* <HelpListItem
          afterText={
            <>
              For teams, the player must have played{" "}
              <strong>at least one game</strong> for that team at AFL/VFL level,
              or currently on their list.
            </>
          }
        /> */}
        {/* <HelpListItem
          bgColor="bg-green-700"
          textColor="text-white"
          highlightText="TIPS"
          afterText={
            <>
              &nbsp;For help, <strong>click the row or column header</strong>{" "}
              for more information about the criteria.
            </>
          }
        /> */}
        {/* <HelpListItem
          afterText={
            <>
              For awards and statuses, the player <strong>does not</strong> have
              to have earned the accomplishment while on that team.
            </>
          }
        /> */}
        {/* <HelpListItem
          afterText={
            <>
              <strong>Rarity scores</strong> are based on how unique your
              selections are vs. other BikeGrid players. Select unique players to
              keep your rarity score low!
            </>
          }
        /> */}
        <HelpListItem afterText="A rider cannot be used more than once. A rarer pick will help you achieve a better score." />
        <HelpListItem
          afterText={
            <>
              You have one "daily undo" to correct a mistake or try to find a
              rarer pick. Use it wisely.
            </>
          }
        />
        <HelpListItem afterText="Come back each day for a new grid!" />
        <HelpListItem afterText="Cycling's history is not straightforward, and our data classifies results as per the time they were held, rather than their current classification. Doping sanctions are reflected by stripped results, but riders that remain retain their original positions in the results sheet." />

        <HelpListItem
          afterText={
            <>
              If you have any issues or feedback,{" "}
              <a
                className="font-medium text-orange-400"
                href="mailto:bikegridsupport@escapecollective.com"
              >
                please get in touch
              </a>
              .
            </>
          }
        />
      </ul>
    </div>
  );
}

function HelpListItem({
  bgColor = "",
  textColor = "",
  highlightText = "",
  afterText,
}) {
  return (
    <li>
      {highlightText !== "" && (
        <span
          className={`${bgColor} ${textColor} -m-0.5 inline-block rounded-sm px-1 py-0.5 font-medium`}
        >
          {highlightText}
        </span>
      )}{" "}
      {afterText}
    </li>
  );
}

export default HowToPlayDialog;
