import ShareButton from "../buttons/ShareButton";
import { scoreWords } from "../../lib/utils/constants";
import CountUp from "react-countup";
import { calcRarity } from "../../lib/utils/calcRarity";
import TweetButton from "../buttons/TweetButton";
import ToolTip from "./ToolTip";
import { BaseDialog } from "./BaseDialog";
import { DailySummary } from "./StatsDialog";
import { rarityScheme } from "../../lib/utils/rarityScheme";
import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";

function ResultDialog(props) {
  const [isOpen, setIsOpen, globalStats, levelNumber] = useGameStore(
    (state) => [
      state.isResultOpen,
      state.setIsResultOpen,
      state.globalStats,
      state.levelNumber,
    ]
  );
  const [score, correctGuesses] = usePersistentStore((state) => [
    state.score,
    state.correctGuesses,
  ]);

  const rarity = calcRarity(correctGuesses, globalStats);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <BaseDialog
      isOpen={isOpen}
      closeModal={closeModal}
      modalTitle={
        <div className="">
          <div className="text-center text-2xl font-semibold uppercase dark:text-white">
            Summary
          </div>
          <div className="text-center text-sm font-semibold uppercase text-dark-grey dark:text-gray-300">
            BikeGrid Game {levelNumber}
          </div>
        </div>
      }
      modalBody={
        <ModalBody score={score} rarity={rarity} footer={props.footer} />
      }
    />
  );
}

function ModalBody({ score, rarity, footer }) {
  const scoreWord = scoreWords[score];

  return (
    <div className="-mb-4 mt-2 text-center">
      <div>
        <div className="flex justify-center">
          <ResultCell row={1} col={1} />
          <ResultCell row={1} col={2} />
          <ResultCell row={1} col={3} />
        </div>
        <div className="flex justify-center">
          <ResultCell row={2} col={1} />
          <ResultCell row={2} col={2} />
          <ResultCell row={2} col={3} />
        </div>
        <div className="flex justify-center">
          <ResultCell row={3} col={1} />
          <ResultCell row={3} col={2} />
          <ResultCell row={3} col={3} />
        </div>
      </div>
      <div className="mt-4">
        <div className="flex items-center justify-center text-center text-sm  sm:text-base">
          <span className="font-semibold uppercase text-gray-700 dark:text-gray-200 ">
            Rarity Score
          </span>
          <ToolTip
            title="Rarity score is calculated as the sum of the percentages for each correct cell, plus 100 for each empty cell. A lower score means your answers are more rare. This score will change throughout the day as more games are completed."
            iconClassName="ml-1 h-4 w-4"
          />
        </div>
        <div className="text-center text-5xl font-semibold leading-tight tracking-tight">
          <CountUp
            decimals={rarity < 100 ? 1 : 0}
            end={rarity}
            useEasing={false}
            duration={0.4}
          />
        </div>
      </div>
      <div className="my-2 mt-2 border-solid border-slate-200 px-2.5">
        <h2 className="mb-5 text-2xl font-medium uppercase leading-tight md:text-3xl">
          {scoreWord}!
        </h2>
        <div className="mt-6 flex items-center justify-center sm:mt-8">
          <TweetButton />
          <ShareButton />
        </div>
      </div>
      <div className="-mx-6 mt-6 bg-soft-grey px-4 py-4 text-center">
        <p className="font-bold ">Want more?</p>
        <p>
          Visit{" "}
          <a
            href="https://escapecollective.com/?utm_source=bikegrid&utm_medium=affiliate&utm_campaign=results"
            target="_blank"
            className="font-bold text-orange-400"
          >
            Escape Collective
          </a>{" "}
          for the latest cycling news from around the world.
        </p>
      </div>
    </div>
  );
}

function ResultCell({ row, col }) {
  const [correctGuesses, cardMode] = usePersistentStore((state) => [
    state.correctGuesses,
    state.cardMode,
  ]);
  const [globalStats] = useGameStore((state) => [state.globalStats]);

  const correctAnswersPlayerMap =
    globalStats?.correctAnswersPlayerMap[row - 1][col - 1];
  const correctPlayerId = correctGuesses[row - 1][col - 1];
  const pct = correctAnswersPlayerMap
    ? (correctAnswersPlayerMap[correctPlayerId] / globalStats?.completed) * 100
    : 0.1;

  const scheme = rarityScheme(pct);
  const bg = cardMode ? scheme.id + "-flat" : "bg-[#59d185]";

  return (
    <div
      className={`bg-x-center m-0.5 h-8 w-8 rounded-lg md:h-12 md:w-12 ${
        correctPlayerId ? bg : "bg-soft-grey dark:bg-gray-700"
      } `}
    ></div>
  );
}

export default ResultDialog;
