import { useState } from "react";
import useGameStore from "../../lib/store/GameStore";
import { BaseDialog } from "./BaseDialog";

function PromoDialog({ modalTitle = "Game Update" }) {
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle={modalTitle}
        modalBody={<HowToPlayBody />}
        size="md"
      />
    </>
  );
}

function HowToPlayBody() {
  return (
    <div className="my-2 space-y-4 px-2 pt-2 text-md sm:px-4">
      <div>
        New riders have just landed in BikeGrid, allowing you to select any
        rider from the <span className="font-semibold">past 20 years</span>.{" "}
        Good luck!
      </div>
    </div>
  );
}

function HelpListItem({
  bgColor = "",
  textColor = "",
  highlightText = "",
  afterText,
}) {
  return (
    <li>
      {highlightText !== "" && (
        <span
          className={`${bgColor} ${textColor} -m-0.5 inline-block rounded-sm px-1 py-0.5 font-medium`}
        >
          {highlightText}
        </span>
      )}{" "}
      {afterText}
    </li>
  );
}

export default PromoDialog;
