import { format } from "date-fns";
import { en } from "date-fns/locale";

const locales = {
  en: en,
};

function setLocale(params) {
  const userLocale = navigator.language || "en-US";
  const locale = userLocale.split("-")[0];
  window.__localeId__ = locale;
}

export function formatDate(date, formatStr = "PP") {
  if (typeof window === "undefined") {
    return format(date, formatStr, {
      locale: en,
    });
  }

  if (!window.__localeId__) {
    setLocale();
  }

  return format(date, formatStr, {
    locale: locales[window.__localeId__ || en],
  });
}
