import { BaseDialog } from "./BaseDialog";
import Image from "next/image";
import secondShotLogo from "../../../public/img/secondshot.png";
import usePersistentStore from "../../lib/store/PersistentStore";

export function UndoDialog({ player, row, col, isOpen, closeModal }) {
  const [undoGuess] = usePersistentStore((state) => [state.undoGuess]);

  async function handleConfirm() {
    await undoGuess(player, row, col);
    closeModal(true);
  }

  function handleCancel() {
    closeModal(false);
  }

  return (
    <BaseDialog
      isOpen={isOpen}
      closeModal={closeModal}
      modalTitle={""}
      modalBody={
        <ModalBody handleConfirm={handleConfirm} handleCancel={handleCancel} />
      }
      size="lg"
    />
  );
}

function ModalBody({ handleConfirm, handleCancel }) {
  return (
    <>
      <div className="text-center">
        <div className="mx-auto mb-4 text-center">
          {/* <svg
            className="mx-auto h-16 w-16 text-black"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 940 1000"
          >
            <path d="M532 90c113.333 0 209.667 40 289 120s119 176.667 119 290-39.667 210-119 290-175.667 120-289 120c-92 0-176-29.333-252-88l70-76c54.667 40 115.333 60 182 60 84 0 156-30 216-90s90-132 90-216c0-85.333-30-158-90-218s-132-90-216-90c-82.667 0-153.667 28.667-213 86s-90.333 127.333-93 210h142L184 694 0 488h124c2.667-110.667 43.667-204.667 123-282S421.333 90 532 90m-36 190h70v204l130 130-50 50-150-150V280" />
          </svg> */}
          <Image
            src={secondShotLogo}
            alt="Second Chance"
            layout="intrinsic"
            width={80}
            height={80}
          />
        </div>
        <h3 className="mb-4 text-xl font-bold  dark:text-gray-400">
          Confirm Undo
        </h3>
        <p className="-mx-1.5 mb-8 text-md font-normal  dark:text-gray-400">
          Each day you get <span className="font-bold">one chance</span> to undo
          a guess. Are you sure you want to use your daily undo?
        </p>

        <div className="mb-4 flex items-center justify-center space-x-4">
          <button
            onClick={handleCancel}
            data-modal-hide="popup-modal"
            type="button"
            className="rounded-lg border border-gray-300 bg-white px-5 py-2.5 text-sm font-medium text-gray-700 hover:bg-soft-grey hover:text-black focus:z-10 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600"
          >
            No, cancel
          </button>
          <button
            onClick={handleConfirm}
            data-modal-hide="popup-modal"
            type="button"
            className="inline-flex items-center rounded-lg bg-orange-400 px-5 py-2.5 text-center text-sm font-medium text-black hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2 dark:focus:ring-green-800"
          >
            Yes, undo
          </button>
        </div>
      </div>
    </>
  );
}
