import { shallow } from "zustand/shallow";
import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";
import { getPlayerById } from "../../lib/utils/helpers";
import { BaseDialog } from "./BaseDialog";
import { GridHeadshot } from "./Headshots";

function PossibleAnswersDialog({ isOpen, closeModal, selectionStats }) {
  const [user] = useGameStore((state) => [state.user]);

  return (
    <BaseDialog
      isOpen={isOpen}
      closeModal={closeModal}
      modalTitle={
        <div className="text-center text-xl font-semibold uppercase dark:text-white">
          {user?.subscriber ? "Possible Answers" : "Top Answers"}
        </div>
      }
      modalBody={<ModalBody selectionStats={selectionStats} />}
      size="md"
    />
  );
}

function ModalBody({ selectionStats }) {
  const [user] = useGameStore((state) => [state.user]);
  const [guessesLeft] = usePersistentStore(
    (state) => [state.guessesLeft],
    shallow
  );

  const attemptComplete = guessesLeft <= 0;

  return attemptComplete ? (
    <div className="-mx-3 -mb-3">
      <ul className="z-50 space-y-1 border-t border-t-gray-100 px-2 py-2 text-sm text-black dark:bg-gray-700">
        {selectionStats.map(
          (selection, index) =>
            (index < 3 || user?.subscriber) && (
              <PossiblePlayerItem
                key={selection.playerId}
                playerId={selection.playerId}
                count={selection.count}
                pct={selection.pct}
                maxPct={selectionStats[0].pct}
              />
            )
        )}
        {!user?.subscriber && (
          <>
            <div className="flex select-none items-center justify-between rounded-xl px-3 py-2.5 text-sm font-normal leading-snug ">
              <div className="flex items-center justify-start">
                <span>
                  Become an{" "}
                  <a
                    href="https://escapecollective.com/join/"
                    target="_blank"
                    className="font-semibold text-orange-400 hover:underline"
                  >
                    Escape Collective member
                  </a>{" "}
                  and see all {selectionStats.length} possible answers.
                </span>
              </div>
            </div>
          </>
        )}
      </ul>
    </div>
  ) : (
    <div className="z-50 space-y-1 border-t border-t-gray-100 pb-6 pt-8 text-center text-sm text-black dark:bg-gray-700">
      Play today's grid, then see the possible answers
    </div>
  );
}

function PossiblePlayerItem({ playerId, count, pct, maxPct }) {
  const player = getPlayerById(playerId);
  const correct = true;

  const barPct = (pct / maxPct) * 100;

  return (
    <div>
      <div
        className={`flex cursor-default select-none items-center justify-between rounded-lg px-3 py-2.5 font-medium leading-snug hover:bg-off-white`}
      >
        <div className="flex items-center justify-start">
          {/* <div className="mr-3 flex aspect-square h-10 cursor-pointer items-center justify-center overflow-hidden">
            <div className="overflow group relative flex h-full w-full  items-start justify-center">
              <GridHeadshot player={player} />{" "}
            </div>
          </div> */}
          <div className="mr-2 aspect-4/3 h-4">
            <img src={`/img/flags/${player?.country}.svg`} />
          </div>
          <span>{player?.name}</span>
        </div>
        {!isNaN(pct) && (
          <div className="flex items-center justify-start">
            {(pct * 100).toFixed(1)}%
          </div>
        )}
      </div>
      {/* <div className="h-1 bg-amber-600" style={{ width: barPct * 2 }}></div> */}
    </div>
  );
}

export function MiniGrid({ col, row, correct }) {
  const cells = [];

  for (let r = 1; r <= 3; r++) {
    for (let c = 1; c <= 3; c++) {
      const color =
        r != row || c != col
          ? "bg-gray-200 group-[.user-row]:bg-orange-50"
          : correct
          ? "bg-green-600"
          : "bg-red-600";

      cells.push(
        <div
          key={`${col}-${row}-${r}-${c}`}
          className={`aspect-square rounded-sm ${color}`}
        ></div>
      );
    }
  }

  return <div className="grid shrink grid-cols-3 gap-px">{cells}</div>;
}

export default PossibleAnswersDialog;
