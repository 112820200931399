import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useRef, useEffect, forwardRef } from "react";
import Grow from "@mui/material/Grow";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      easing={{
        enter: "cubic-bezier(0,0.7,.15,.95)",
      }}
      {...props}
    />
  );
});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
    },
  },
});

export function BaseDialog({
  isOpen,
  closeModal,
  modalTitle,
  modalBody,
  size = "xl",
  dark = false,
}) {
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Dialog
          open={isOpen}
          onClose={closeModal}
          TransitionComponent={Transition}
          transitionDuration={{ enter: 500, exit: 200 }}
          scroll="body"
          fullWidth={true}
          componentsProps={{
            backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.25)" } },
          }}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              alignItems: "flex-start",
              margin: "0px",
              display: "block",
            },
          }}
          PaperProps={{
            className: `max-w-[calc(100%_-_2rem)] overflow-hidden ${
              size == "md"
                ? "sm:max-w-md"
                : size == "lg"
                ? "sm:max-w-lg"
                : "sm:max-w-xl"
            } w-full m-4 sm:mx-6 md:mx-20`,
            sx: {
              margin: "0 auto",
              borderRadius: "0.75rem",
              overflowY: "hidden",
              // maxWidth: "32rem !important",
            },
          }}
        >
          <DialogTitle
            id="scroll-dialog-title"
            className={`${
              dark && "dark"
            } p-4 text-center text-lg font-bold leading-tight`}
            sx={{ fontFamily: "inherit", padding: 0 }}
          >
            <ModalCloseButton clickCallback={closeModal} />
            {modalTitle}
          </DialogTitle>
          <div className="px-4 pb-4">{modalBody}</div>
          {/* <DialogContent
            dividers={false}
            className="p-4 pb-6 md:p-6 md:pb-8"
            sx={{ padding: 0 }}
          ></DialogContent> */}
        </Dialog>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export function ModalCloseButton({ clickCallback }) {
  return (
    <button
      type="button"
      className="absolute right-1.5 -m-1.5 mr-2 rounded-full p-1.5 transition-colors duration-75 hover:bg-mid-grey/30 focus-visible:outline focus-visible:outline-2 focus-visible:outline-orange-400 dark:text-gray-200 dark:hover:bg-white/20"
      onClick={clickCallback}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        className="h-6 w-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>
  );
}
