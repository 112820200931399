const teamMap = {
  AD: {
    id: 10,
    logoId: 1,
    emoji: "🐦‍⬛",
    full_name: "Adelaide Crows",
    name: "Adelaide Crows",
    short_name: "ADE",
    close: ["CD_T10", "CD_T110"],
    colors: ["red", "yellow", "blue"],
    state: "SA",
  },
  BB: {
    id: 20,
    logoId: 2,
    emoji: "🐨",
    full_name: "Brisbane Bears",
    name: "Brisbane Bears",
    short_name: "BRI",
    close: ["CD_T20", "CD_T1000"],
    colors: ["maroon", "yellow"],
    state: "QLD",
  },
  BL: {
    id: 20,
    logoId: 2,
    emoji: "🦁",
    full_name: "Brisbane Lions",
    name: "Brisbane Lions",
    short_name: "BRL",
    close: ["CD_T20", "CD_T1000"],
    colors: ["maroon", "yellow", "blue"],
    state: "QLD",
  },
  CA: {
    id: 30,
    logoId: 3,
    emoji: "🔵",
    full_name: "Carlton",
    name: "Carlton",
    short_name: "CAR",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["blue"],
    state: "VIC",
  },
  CW: {
    id: 40,
    logoId: 4,
    emoji: "🥧",
    full_name: "Collingwood",
    name: "Collingwood",
    short_name: "COL",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["black", "white"],
    state: "VIC",
  },
  ES: {
    id: 50,
    logoId: 5,
    emoji: "🛩️",
    full_name: "Essendon",
    name: "Essendon",
    short_name: "ESS",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["red", "black"],
    state: "VIC",
  },
  FI: {
    id: 60,
    logoId: 100,
    emoji: "🦁",
    full_name: "Fitzroy Lions",
    name: "Fitzroy",
    short_name: "FITZ",
    colors: ["red", "blue", "yellow"],
    state: "VIC",
  },
  FR: {
    id: 60,
    logoId: 6,
    emoji: "⚓",
    full_name: "Fremantle",
    name: "Fremantle",
    short_name: "FRE",
    close: ["CD_T60", "CD_T150"],
    colors: ["purple", "white"],
    state: "WA",
  },
  GE: {
    id: 70,
    logoId: 8,
    emoji: "🐱",
    full_name: "Geelong Cats",
    name: "Geelong Cats",
    short_name: "GEE",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["white", "blue"],
    state: "VIC",
  },
  GC: {
    id: 1000,
    logoId: 7,
    emoji: "☀️",
    full_name: "Gold Coast Suns",
    name: "Gold Coast Suns",
    short_name: "GCS",
    close: ["CD_T20", "CD_T1000"],
    colors: ["red", "yellow"],
    state: "QLD",
  },
  GW: {
    id: 1010,
    logoId: 9,
    emoji: "🟠",
    full_name: "GWS Giants",
    name: "GWS Giants",
    short_name: "GWS",
    close: ["CD_T1010", "CD_T160"],
    colors: ["grey", "orange", "white"],
    state: "NSW",
  },
  HW: {
    id: 80,
    logoId: 10,
    emoji: "🟤",
    full_name: "Hawthorn",
    name: "Hawthorn",
    short_name: "HAW",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["brown", "yellow"],
    state: "VIC",
  },
  ME: {
    id: 90,
    logoId: 11,
    emoji: "👹",
    full_name: "Melbourne",
    name: "Melbourne",
    short_name: "MEL",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["red", "blue"],
    state: "VIC",
  },
  KA: {
    id: 100,
    logoId: 12,
    emoji: "🦘",
    full_name: "North Melbourne",
    name: "North Melbourne",
    short_name: "NTH",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["white", "blue"],
    state: "VIC",
  },
  PA: {
    id: 110,
    logoId: 13,
    emoji: "⚡",
    full_name: "Port Adelaide",
    name: "Port Adelaide",
    short_name: "PTA",
    close: ["CD_T10", "CD_T110"],
    colors: ["black", "white", "teal"],
    state: "SA",
  },
  RI: {
    id: 120,
    logoId: 14,
    emoji: "🐯",
    full_name: "Richmond",
    name: "Richmond",
    short_name: "RIC",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["yellow", "black"],
    state: "VIC",
  },
  SK: {
    id: 130,
    logoId: 15,
    emoji: "😇",
    full_name: "St Kilda",
    name: "St Kilda",
    short_name: "STK",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["red", "white", "black"],
    state: "VIC",
  },
  SY: {
    id: 160,
    logoId: 16,
    emoji: "🦢",
    full_name: "Sydney Swans",
    name: "Sydney Swans",
    short_name: "SYD",
    close: ["CD_T1010", "CD_T160"],
    colors: ["red", "white"],
    state: "NSW",
  },
  WC: {
    id: 150,
    logoId: 18,
    emoji: "🦅",
    full_name: "West Coast Eagles",
    name: "West Coast Eagles",
    short_name: "WCE",
    close: ["CD_T60", "CD_T150"],
    colors: ["yellow", "blue"],
    state: "WA",
  },
  WB: {
    id: 140,
    logoId: 17,
    emoji: "🐶",
    full_name: "Western Bulldogs",
    name: "Western Bulldogs",
    short_name: "WBD",
    close: [
      "CD_T30",
      "CD_T40",
      "CD_T50",
      "CD_T70",
      "CD_T80",
      "CD_T90",
      "CD_T100",
      "CD_T120",
      "CD_T130",
      "CD_T140",
    ],
    colors: ["red", "white", "blue"],
    state: "VIC",
  },
};

export default teamMap;
